/* You can add global styles to this file, and also import other style files */
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'node_modules/angular-notifier/styles';

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
body {
  margin: 0;
}
.mat-subtitle {
  margin-top: -20px;
}
.panel-sidebar {
  max-height: 600px;
  overflow: auto;
  small {
    font-weight: 400;
    font-size: 0.8em;
  }
}
.jss8 {
  box-shadow: none !important;
}

.jss2 {
  max-width: initial !important;
}

label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
