@charset "UTF-8";

@font-face {
  font-family: 'mf';
  src: url('fonts/mf.eot?v=1');
  src:
    url('fonts/mf.eot?#iefix?v=1') format('embedded-opentype'),
    url('fonts/mf.woff?v=1') format('woff'),
    url('fonts/mf.ttf?v=1') format('truetype'),
    url('fonts/mf.svg#mf?v=1') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'mf' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'mf' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-baby-boy:before {
  content: '\61';
}
.icon-cap:before {
  content: '\62';
}
.icon-car:before {
  content: '\63';
}
.icon-chevron-arrow-down:before {
  content: '\64';
}
.icon-credit-card:before {
  content: '\66';
}
.icon-cross:before {
  content: '\67';
}
.icon-disabled:before {
  content: '\69';
}
.icon-drink:before {
  content: '\6b';
}
.icon-groceries:before {
  content: '\6d';
}
.icon-heart:before {
  content: '\6e';
}
.icon-house:before {
  content: '\6f';
}
.icon-insurance:before {
  content: '\70';
}
.icon-just-married:before {
  content: '\71';
}
.icon-man:before {
  content: '\73';
}
.icon-mortgage:before {
  content: '\76';
}
.icon-question:before {
  content: '\77';
}
.icon-rent:before {
  content: '\78';
}
.icon-shop:before {
  content: '\7a';
}
.icon-startup:before {
  content: '\41';
}
.icon-wheelchair:before {
  content: '\43';
}
.icon-woman:before {
  content: '\65';
}
.icon-round-account-button-with-user-inside:before {
  content: '\6c';
}
.icon-boy:before {
  content: '\74';
}
.icon-assets:before {
  content: '\42';
}
.icon-girl:before {
  content: '\44';
}
.icon-plus:before {
  content: '\45';
}
.icon-drag:before {
  content: '\46';
}
.icon-cancel:before {
  content: '\48';
}
.icon-coffee:before {
  content: '\49';
}
.icon-umbrella:before {
  content: '\4a';
}
.icon-coffee-togo:before {
  content: '\4b';
}
.icon-rain:before {
  content: '\4d';
}
.icon-cloud-sun:before {
  content: '\4e';
}
.icon-arrow-right:before {
  content: '\4f';
}
.icon-paper-plane:before {
  content: '\50';
}
.icon-phone:before {
  content: '\51';
}
.icon-calendar:before {
  content: '\52';
}
.icon-mail:before {
  content: '\53';
}
.icon-repeat:before {
  content: '\54';
}
.icon-debt:before {
  content: '\68';
}
.icon-dollar:before {
  content: '\56';
}
.icon-money:before {
  content: '\75';
}
.icon-loan:before {
  content: '\72';
}
.icon-bigender:before {
  content: '\6a';
}
.icon-tree:before {
  content: '\55';
}
.icon-bond:before {
  content: '\57';
}
.icon-sprout:before {
  content: '\58';
}
.icon-necklace:before {
  content: '\5a';
}
.icon-home-insurance:before {
  content: '\30';
}
.icon-income-protection:before {
  content: '\31';
}
.icon-income-protection-2:before {
  content: '\32';
}
.icon-capital:before {
  content: '\33';
}
.icon-car-1:before {
  content: '\34';
}
.icon-child-girl:before {
  content: '\35';
}
.icon-child-boy:before {
  content: '\36';
}
.icon-cart:before {
  content: '\37';
}
.icon-engagement:before {
  content: '\38';
}
.icon-airplane:before {
  content: '\39';
}
.icon-sprout-1:before {
  content: '\21';
}
.icon-tree-1:before {
  content: '\22';
}
.icon-capital-retire:before {
  content: '\24';
}
.icon-sun:before {
  content: '\4c';
}
.icon-sunny-day:before {
  content: '\23';
}
.icon-female:before {
  content: '\25';
}
.icon-male:before {
  content: '\26';
}
.icon-retirement:before {
  content: '\79';
}
.icon-world:before {
  content: '\47';
}
.icon-premium:before {
  content: '\59';
}
.icon-pool:before {
  content: '\27';
}
.icon-savings:before {
  content: '\28';
}
