@charset "UTF-8";

@font-face {
  font-family: 'envizage-admin';
  src: url('fonts/envizage-admin.eot');
  src:
    url('fonts/envizage-admin.eot?#iefix') format('embedded-opentype'),
    url('fonts/envizage-admin.woff') format('woff'),
    url('fonts/envizage-admin.ttf') format('truetype'),
    url('fonts/envizage-admin.svg#envizage-admin') format('svg');
  font-weight: normal;
  font-style: normal;
}

.envizage:before {
  font-family: 'envizage-admin' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: '\61';
}
.icon-building:before {
  content: '\63';
}
.icon-flow:before {
  content: '\64';
}
.icon-home:before {
  content: '\66';
}
.icon-logout:before {
  content: '\67';
}
.icon-plus:before {
  content: '\68';
}
.icon-script:before {
  content: '\69';
}
.icon-settings:before {
  content: '\6a';
}
.icon-group:before {
  content: '\6b';
}
.icon-user:before {
  content: '\65';
}
.icon-overview:before {
  content: '\6c';
}
.icon-lock:before {
  content: '\6d';
}
.icon-terminal:before {
  content: '\6e';
}
.icon-credit-card:before {
  content: '\6f';
}
.icon-devices:before {
  content: '\70';
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}
